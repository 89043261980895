<template>
	<div>
		<app-app-header v-model="indexStore.isHeaderVisible" main/>

		<app-content>
			<page-header :title="$t('view.about.welcome.title')" :image="featuredEvent ? featuredEvent.image : '/views/welcome/loeffler.jpg'" large>
				<template #image>
					<nuxt-link v-if="featuredEvent" :to="$web('events.show', featuredEvent.event._key)"
							   class="block absolute left-0 right-0 bottom-0 top-0">
						<container class="h-full">
							<div class="h-full w-full flex justify-end items-center">
								<div class="shadow relative rounded-lg px-8 md:px-12 lg:px-16 py-8 md:py-12 lg:py-16 xl:py-20 xl:px-20 overflow-hidden md:mb-100 xl:mb-64">
									<div class="absolute opacity-75 top-0 left-0 right-0 bottom-0 bg-white"/>
									<div class="relative flex flex-column">
										<p class="text-right text-xs md:text-sm lg:text-lg xl:text-xl leading-tight font-heading font-semi-bold mb-2 sm:mb-4"
										   :class="{'text-text-color': !featuredEvent.color}" :style="{color: featuredEvent.color}">{{
												featuredEvent.event.display_name
											}}</p>
										<p class="flex justify-end font-heading mb-0 w-full overflow-hidden">
											<meta-text :color="featuredEvent.color" class="flex leading-tight text-3xs sm:text-2xs md:text-xs lg:text-sub">
												{{ $date(featuredEvent.event.start_date).formatDateRange(featuredEvent.event.end_date) }}
											</meta-text>
											<meta-text :color="featuredEvent.color" class="flex leading-tight text-3xs sm:text-2xs md:text-xs lg:text-sub overflow-hidden flex">
												<span class="truncate">{{ featuredEvent.event.city }}</span>
												<span class="whitespace-no-wrap">{{ featuredEvent.event.city ? ', ' : '' }}{{
														featuredEvent.event.country_code.toUpperCase() }}</span>
											</meta-text>
										</p>
									</div>
									<btn :color="featuredEvent.color ? featuredEvent.color : 'primary'" class="w-full mt-12 lg:mt-20">
										<span>{{ $t('action.to_event') }}</span>
										<icon :icon="icons.next"/>
									</btn>
								</div>
							</div>
						</container>
					</nuxt-link>
				</template>

				<form :action="$web('search')" @submit.prevent="handleSearchSubmit">
					<wrapper stretch break-column="sm">
						<wrapper stretch>
							<form-input hide-details enterkeyhint="search" inverted x-large v-model="query" name="search" :placeholder="$t('placeholder.search_anything')"
										:icon="icons.search"/>

							<btn v-if="query && query.length" x-large color="primary" type="submit" class="sm:min-w-200">
								<icon :icon="icons.search"/>
								<span class="none sm:block">{{ $t('action.search') }}</span>
							</btn>
						</wrapper>

						<btn :to="$config.public.aboutUrl" color="primary" class="sm:h-52" outlined>
							<span>{{ $t('action.more_information') }}</span>
							<icon :icon="icons.next"/>
						</btn>
					</wrapper>

				</form>
			</page-header>

			<page-content mobile-tight>
				<feature-section label="events" lang-prefix="view.about.events" full-width cta-stretch>
					<template #text>
						<div v-if="competitions.length" class="mx--16 md:mx-0 mb-24">
							<slider show-arrows visible-arrows full-width-spaced>
								<slider-item v-for="(event, eventKey) in competitions" :key="'event-suggest-' + event.id">
									<event-card :event="event" :to="$app('events-event', {params: {event: event._key}, state: {event_ts: event.cache_buster}})" :position="eventKey + 1" list-name="Landing Page" class="w-240"/>
								</slider-item>
								<slider-item>
									<card class="w-240 h-240" :to="$web('events.index')">
										<div class="flex flex-column items-center justify-center h-full w-full">
											<icon :icon="icons.search" class="mb-8"/>
											<span>{{ $t('action.show_all') }}</span>
										</div>
									</card>
								</slider-item>
							</slider>
						</div>

						<h3 class="mb-20">{{ $t('action.filter_name_by_region_and_sport', {name: $t('label.competitions')}) }}</h3>
						<chip v-for="(sportTypeRegion, sportTypeRegionIndex) in competitionSportTypeRegions"
							  :key="sportTypeRegionIndex"
							  :to="`/events?sport=${sportTypeRegion.sport_type_slug}&format=${sportTypeRegion.sport_type_format_slug}&country=${sportTypeRegion.country_code}&state=${sportTypeRegion.state_code}`"
							   class="m-4">
							<span>{{ sportTypeRegion.sport_type_title }}: {{ sportTypeRegion.sport_type_format_title }} {{ $t('conjunction.in') }} {{ sportTypeRegion.state_title }}</span>
						</chip>
						<chip to="/events"
							  class="m-4">
							<span>{{ $t('action.show_more') }} ...</span>
						</chip>
					</template>

					<template #cta>
						<btn :to="$web('events.index')" color="primary" large>
							<span>{{ $t('action.to_competition_calendar') }}</span>
						</btn>
						<btn :to="`${$config.public.aboutUrl}/spoferan/events`" color="primary" outlined large>
							<span>{{ $t('action.more_information') }}</span>
							<icon :icon="icons.next"/>
						</btn>
					</template>
				</feature-section>

				<feature-section label="clubs" lang-prefix="view.about.clubs" full-width cta-stretch>
					<template #text>
						<div v-if="clubs.length" class="mx--16 md:mx-0 mb-24">
							<slider show-arrows visible-arrows full-width-spaced>
								<slider-item v-for="club in clubs" :key="'club-suggest-' + club.id">
									<club-card :club="club" :to="$web('clubs.show', club._key)" class="w-200"/>
								</slider-item>
								<slider-item>
									<card class="w-200 h-180" :to="$web('clubs.index')">
										<div class="flex flex-column items-center justify-center h-full w-full">
											<icon :icon="icons.search" class="mb-8"/>
											<span>{{ $t('action.show_all') }}</span>
										</div>
									</card>
								</slider-item>
							</slider>
						</div>

						<h3 class="mb-20">{{ $t('action.filter_name_by_region_and_sport', {name: $t('label.clubs')}) }}</h3>
						<chip v-for="(sportTypeRegion, sportTypeRegionIndex) in clubSportTypeRegions"
							  :key="sportTypeRegionIndex"
							  :to="`/clubs?sport=${sportTypeRegion.sport_type_slug}&country=${sportTypeRegion.country_code}&state=${sportTypeRegion.state_code}`"
							  class="m-4">
							<span>{{ sportTypeRegion.sport_type_title }}{{ $t('label.clubs').toLowerCase() }} {{ $t('conjunction.in') }} {{ sportTypeRegion.state_title }}</span>
						</chip>
						<chip to="/clubs"
							  class="m-4">
							<span>{{ $t('action.show_more') }} ...</span>
						</chip>
					</template>

					<template #cta>
						<btn :to="$web('clubs.index')" color="primary" large>
							<span>{{ $t('action.to_club_map') }}</span>
						</btn>
						<btn :to="`${$config.public.aboutUrl}/spoferan#clubs-map`" large color="primary" outlined>
							<span>{{ $t('action.more_information') }}</span>
							<icon :icon="icons.next"/>
						</btn>
					</template>
				</feature-section>

				<feature-section label="articles" lang-prefix="view.about.articles" full-width cta-stretch>
					<template #text>
						<div v-if="articles.length" class="mx--16 md:mx-0 mb-24">
							<slider show-arrows visible-arrows full-width-spaced>
								<slider-item v-for="article in articles" :key="`articles-${article.id}`">
									<article-card :article="article" class="h-440 w-304"/>
								</slider-item>
								<slider-item>
									<card class="h-440 w-304" :to="$app('news')">
										<div class="flex flex-column items-center justify-center h-full w-full">
											<icon :icon="icons.search" class="mb-8"/>
											<span>{{ $t('action.show_all') }}</span>
										</div>
									</card>
								</slider-item>
							</slider>
						</div>
					</template>

					<template #cta>
						<btn :to="$app('news')" color="primary" large>
							<span>{{ $t('action.show_all_news') }}</span>
						</btn>
						<btn :to="$app('blog')" large color="primary" outlined>
							<span>{{ $t('label.sport_blog') }}</span>
						</btn>
					</template>
				</feature-section>

				<feature-section label="sports-map" lang-prefix="view.about.sports_map" full-width cta-stretch>
					<template v-if="events.length" #text>
						<div class="mx--16 md:mx-0 mb-24">
							<slider show-arrows visible-arrows full-width-spaced>
								<slider-item v-for="(event, eventKey) in events" :key="'event-suggest-' + event.id">
									<event-card :event="event" :to="$app('events-event', {params: {event: event._key}, state: {event_ts: event.cache_buster}})" :position="eventKey + 1" list-name="Landing Page" class="w-240"/>
								</slider-item>
								<slider-item>
									<card class="w-240 h-240" :to="$web('events.sports_map')">
										<div class="flex flex-column items-center justify-center h-full w-full">
											<icon :icon="icons.search" class="mb-8"/>
											<span>{{ $t('action.show_all') }}</span>
										</div>
									</card>
								</slider-item>
							</slider>
						</div>
					</template>

					<template #cta>
						<btn :to="$web('events.sports_map')" color="primary" large>
							<span>{{ $t('action.to_sports_map') }}</span>
						</btn>
						<btn :to="$config.public.aboutUrl" color="primary" outlined large>
							<span>{{ $t('action.more_information') }}</span>
							<icon :icon="icons.next"/>
						</btn>
					</template>
				</feature-section>

				<feature-section label="athletes" lang-prefix="view.about.athletes" full-width cta-stretch>
					<template v-if="users.length" #text>
						<div class="mx--16 md:mx-0 mb-24">
							<slider show-arrows visible-arrows full-width-spaced>
								<slider-item v-for="user in users" :key="'user-suggest-' + user.id">
									<user-card :user="user" :to="$web('users.show', user._key)" class="w-180"/>
								</slider-item>
								<slider-item>
									<card class="w-180 h-240" :to="$web('users.index')">
										<div class="flex flex-column items-center justify-center h-full w-full">
											<icon :icon="icons.search" class="mb-8"/>
											<span>{{ $t('action.show_all') }}</span>
										</div>
									</card>
								</slider-item>
							</slider>
						</div>
					</template>

					<template #cta>
						<btn :to="$web('users.index')" color="primary" large>
							<span>{{ $t('action.user.index') }}</span>
						</btn>
						<btn :to="`${$config.public.aboutUrl}/spoferan`" large color="primary" outlined>
							<span>{{ $t('action.more_information') }}</span>
							<icon :icon="icons.next"/>
						</btn>
					</template>
				</feature-section>

				<feature-section label="sports" lang-prefix="view.about.sport_types" full-width cta-stretch>
					<template v-if="sportTypes.length" #text>
						<div class="mx--16 md:mx-0 mb-24">
							<slider show-arrows visible-arrows full-width-spaced>
								<slider-item v-for="sport in sportTypes" :key="'sport-suggest-' + sport.id">
									<sport-type-card :sport="sport" class="w-240"/>
								</slider-item>
								<slider-item>
									<card class="w-240 h-304" :to="$app('sports')">
										<div class="flex flex-column items-center justify-center h-full w-full">
											<icon :icon="icons.search" class="mb-8"/>
											<span>{{ $t('action.show_all') }}</span>
										</div>
									</card>
								</slider-item>
							</slider>
						</div>
					</template>

					<template #cta>
						<btn :to="$app('sports')" color="primary" large>
							<span>{{ $t('action.show_all_sport_types') }}</span>
						</btn>
						<btn :to="`${$config.public.aboutUrl}/spoferan#sports-directory`" color="primary" outlined large>
							<span>{{ $t('action.more_information') }}</span>
							<icon :icon="icons.next"/>
						</btn>
					</template>
				</feature-section>

				<feature-section label="products" lang-prefix="view.about.features" full-width cta-stretch>
					<template #text>
						<row tight v-adapt:height="{selector: '.type-card', xs: 6, sm: 3, md: 3, lg: 3, xl: 3}">
							<column cols="6" sm="3">
								<card :to="$config.public.aboutUrl" xs-tight class="type-card">
									<card-image src="/features/spoferan-meine-sporteventcommunity.jpg" :ratio="2"/>
									<card-title spaced-content>{{ $t('view.about.card_general.title') }}</card-title>
									<card-text>{{ $t('view.about.card_general.description') }}</card-text>
									<btn text color="primary" class="rounded-t-none border-t w-full">
										<span>{{ $t('label.more_information') }}</span>
									</btn>
								</card>
							</column>
							<column cols="6" sm="3">
								<card :to="`${$config.public.aboutUrl}/spoferan`" xs-tight class="type-card">
									<card-image src="views/about/spoferan-for-athletes.png" :ratio="2"/>
									<card-title spaced-content>{{ $t('view.about.card_athletes.title') }}</card-title>
									<card-text>{{ $t('view.about.card_athletes.description') }}</card-text>
									<btn text color="primary" class="rounded-t-none border-t w-full">
										<span>{{ $t('label.more_information') }}</span>
									</btn>
								</card>
							</column>
							<column cols="6" sm="3">
								<card :to="`${$config.public.aboutUrl}/resultastic`" xs-tight class="type-card">
									<card-image src="/views/about/spoferan-for-organizers.png" :ratio="2"/>
									<card-title spaced-content>{{ $t('view.about.card_organizers.title') }}</card-title>
									<card-text>{{ $t('view.about.card_organizers.description') }}</card-text>
									<btn text color="primary" class="rounded-t-none border-t w-full">
										<span>{{ $t('label.more_information') }}</span>
									</btn>
								</card>
							</column>
							<column cols="6" sm="3">
								<card :to="`${$config.public.aboutUrl}/clubmanager`" xs-tight class="type-card">
									<card-image src="/views/about/spoferan-for-clubs.png" :ratio="2"/>
									<card-title spaced-content>{{ $t('view.about.card_clubs.title') }}</card-title>
									<card-text>{{ $t('view.about.card_clubs.description') }}</card-text>
									<btn text color="primary" class="rounded-t-none border-t w-full">
										<span>{{ $t('label.more_information') }}</span>
									</btn>
								</card>
							</column>
						</row>
					</template>
				</feature-section>

				<feature-section lang-prefix="view.about.feature_app" :divided="false" cols="8">
					<template #cta>
						<btn :to="`${$config.public.aboutUrl}/spoferan/app`" large color="primary" outlined>
							<span>{{ $t('label.more_information') }}</span>
							<icon :icon="icons.next"/>
						</btn>
					</template>
					<div class="lg:h-full flex items-center justify-end">
						<app-feature-buttons v-if="!$device.isNative" left/>
					</div>
				</feature-section>
			</page-content>

		</app-content>

		<app-footer :items="footerItems"/>
	</div>
</template>

<script setup lang="ts">
import {useIndexStore} from "../store";
import {useAppFooter} from "../composables/useAppFooter";
import {nextIcon, searchIcon} from "@spoferan/nuxt-spoferan/icons";

const indexStore = useIndexStore();
const {footerItems} = useAppFooter();
const {t} = useI18n();
const {$web} = useNuxtApp();
const config = useRuntimeConfig();
const router = useRouter();

const {data} = await useApiFetch('/v1/views/app', {
	guest: true
});

const featuredEvent = data.featuredEvent;
const clubs = data.clubs;
const clubSportTypeRegions = data.clubSportTypeRegions;
const events = data.events;
const competitions = data.competitions;
const competitionSportTypeRegions = data.competitionSportTypeRegions;
const users = data.users;
const sportTypes = data.sportTypes;
const articles = data.articles;

const icons = {
	next: nextIcon,
	search: searchIcon,
};

const query = ref(null);

const handleSearchSubmit = () => {
	router.push($web('search', {search: query.value}));
};

useMeta({
	title: t('view.about.welcome.title'),
})
</script>